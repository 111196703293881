import React from "react";
import { navigate } from "gatsby";
import { Formik } from "formik";

import { CATEGORIES_ROUTE } from "@routes";

import { login } from "apiClient/auth/login";

import isValidEmail from "utils/isValidEmail";

import withAuth from "contexts/withAuth";

import UserLayout from "layouts/UserLayout";

import LoginContent from "pagesComponents/Login/LoginContent";

const getInitialValues = () => ({
  email: "",
  password: "",
});

class LoginPage extends React.Component {
  state = {
    errors: {},
  };

  validate = (values) => {
    let errors = {};

    if (!values.email) {
      errors.email = "Requerido";
    } else if (!isValidEmail(values.email)) {
      errors.email = "Dirección de correo no valida";
    }

    if (!values.password) {
      errors.password = "Requerido";
    }

    return errors;
  };

  onChangeName = (name) => {
    this.setState((state) => {
      const currentErrors = state.errors;
      delete currentErrors[name];

      return { errors: { ...currentErrors } };
    });
  };

  onSubmit = async (values) => {
    try {
      const result = await login({
        email: values.email,
        password: values.password,
      });

      const { token, user } = result.data;

      this.props.setUser(user);
      this.props.setSession({ email: values.email, token });

      navigate(CATEGORIES_ROUTE);
    } catch (error) {
      const errorStatus = error.response.status;
      const errorMessage = error.response.data.error;

      if (errorStatus === 400) {
        this.setState((state) => ({
          errors: { ...state.errors, email: errorMessage },
        }));
      } else if (errorStatus === 401) {
        this.setState((state) => ({
          errors: { ...state.errors, password: errorMessage },
        }));
      }
    }
  };

  render() {
    return (
      <UserLayout title="Iniciar sesión con su cuenta">
        <Formik
          initialValues={getInitialValues()}
          validate={this.validate}
          onSubmit={this.onSubmit}
        >
          {({ errors, handleChange, ...formikProps }) => (
            <LoginContent
              {...formikProps}
              errors={{ ...errors, ...this.state.errors }}
              handleChange={(event) => {
                this.onChangeName(event.target.name);
                handleChange(event);
              }}
            />
          )}
        </Formik>
      </UserLayout>
    );
  }
}

export default withAuth(LoginPage);
