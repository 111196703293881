import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import { RESET_PASSWORD_ROUTE, REGISTER_ROUTE } from "@routes";

import { CenterAligned, ButtonCss } from "./LoginContent.style";

import Input from "components/Input";
import PasswordInput from "components/PasswordInput";
import Button from "components/Button";
import SeparatorBar from "components/SeparatorBar";

const labels = {
  email: "Dirección de correo eletrónico",
  password: "Contraseña",
};

const FormLogin = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
}) => (
  <>
    <form onSubmit={handleSubmit}>
      <Input
        label={labels.email}
        type="email"
        name="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.email && touched.email && errors.email}
      />
      <PasswordInput
        label={labels.password}
        name="password"
        value={values.password}
        onChange={handleChange}
        onBlur={handleBlur}
        error={errors.password && touched.password && errors.password}
      />
      <CenterAligned>
        <Link to={RESET_PASSWORD_ROUTE}>¿Olvidó su contraseña?</Link>
        <Button css={ButtonCss} type="submit">
          Iniciar Sesión
        </Button>
      </CenterAligned>
    </form>
    <SeparatorBar />
    <span>¿No tiene una cuenta?</span>
    &nbsp;
    <Link to={REGISTER_ROUTE}>Cree una aquí</Link>
  </>
);

FormLogin.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default FormLogin;
