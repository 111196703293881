import axios from "axios";

import { prefixApiDomain } from "../utils";

export function login({ email, password }) {
  return axios.get(prefixApiDomain("/api/login"), {
    params: {
      email,
      password,
    },
  });
}
